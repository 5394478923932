<template>
    <v-app>
        <v-main id="cts_top_page" :style="secondaryBackground">
            <MainContent />
        </v-main>
    </v-app>
</template>

<script>
    const MainContent = () => import('@/components/main-content');
    import '../src/css/index.css';

    export default {
        name: 'App',
        components: { MainContent },
        /* COMPONENT DATA  */
        data() {
            return {
                secondaryBackground: {
                    backgroundColor: 'black',
                    padding: '0px',
                    borderSizing: 'border-box',
                    color: 'white',
                    backgroundImage: `url(${require('@/assets/dark-space-4.jpg')})`,
                    backgroundRepeat: 'repeat',
                },
            };
        },
    };
</script>
