export const LOGIN_USER = 'loginUser';
export const LOGOUT_USER = 'logoutUser';
export const SET_TAX_INFO = 'setTaxInfo';
export const CLEAR_TAX_INFO = 'clearTaxInfo';
export const TOGGLE_COUNT = 'toggleCount';
export const SET_COLLAPSE_STATE = 'setCollapseState';
export const ADD_TAX_INFO = 'addTaxInfo';
export const REMOVE_TAX_INFO = 'removeTaxInfo';
export const UPDATE_TAX_CORRECTIONS = 'updateTaxCorrections';
export const SET_ACTIVE_TAB = 'setActiveTab';
export const SET_SCROLL_TAB = 'setScrollTab';
export const SET_APP_VERSION = 'setAppVersion';
export const REFRESH_TOKEN = 'refreshToken';