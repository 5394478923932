import Vue from 'vue';
import Vuex from 'vuex';
import SecureLS from "secure-ls";
import { KEY } from '../shared/config';
import router from '../router';
import createPersistedState from "vuex-persistedstate";

//Import actions that will mutate application state
import {
    LOGIN_USER,
    LOGOUT_USER,
    SET_TAX_INFO,
    ADD_TAX_INFO,
    REMOVE_TAX_INFO,
    UPDATE_TAX_CORRECTIONS,
    CLEAR_TAX_INFO,
    TOGGLE_COUNT,
    SET_ACTIVE_TAB,
    SET_SCROLL_TAB,
    SET_APP_VERSION,
    SET_COLLAPSE_STATE,
    REFRESH_TOKEN
} from './mutation-types';

Vue.use(Vuex);

//Local session key for holding state
const storageKey = "cts_state"; 

//Application state
const state = () => ({
    appVersion: null,
    currentUser: null,
    activeTab: null,
    scrollTab: null,
    toggleCount: 0,
    collapseState: false,
    allUsersData: []
});

//Used for encrypting local storage
const ls = new SecureLS({encodingType: 'aes', encryptionSecret: KEY, isCompression: false });

//Mutators - Modify application state
const mutations = {

    [LOGIN_USER](state, loginResult) {
        state.currentUser = loginResult;
        state.collapseState = false;
        //Create user data record if one doesn't exist
        var userData = state.allUsersData.filter(x => x.user === this.state.currentUser.username);
        if (userData.length === 0) {
            var newUserData = {
                user: this.state.currentUser.username,
                taxData: []
            };
            state.allUsersData.push(newUserData);
        }
    },

    [LOGOUT_USER](state) {
        state.currentUser = null;
    },

    [REFRESH_TOKEN](state, response) {
        state.currentUser = response;
    },

    [SET_TAX_INFO](state, taxInfoArgs) {
        //Update existing user data record
        var userData = state.allUsersData.filter(x => x.user === state.currentUser.username)[0];
        userData.taxData = taxInfoArgs.taxData;
    },

    [ADD_TAX_INFO](state, taxInfoArgs) {
        //Add new tax data for user
        var userData = state.allUsersData.filter(x => x.user === state.currentUser.username)[0];
        userData.taxData.push(taxInfoArgs.taxData);
    },

    [UPDATE_TAX_CORRECTIONS](state, taxCorrectionArgs) {
        //Update tax corrections for a user's particular upload
        var userData = state.allUsersData.filter(x => x.user === state.currentUser.username)[0];
        const updateIndex = userData.taxData.findIndex(x => x.uploadId === taxCorrectionArgs.uploadId);
        userData.taxData[updateIndex].manualCorrections = taxCorrectionArgs.corrections;
    },

    [CLEAR_TAX_INFO](state) {
        //Clear all existing user data
        var userData = state.allUsersData.filter(x => x.user === state.currentUser.username)[0];
        userData.taxData = [];
    },

    [TOGGLE_COUNT](state) {
        state.toggleCount += 1;
    },

    [SET_ACTIVE_TAB](state, activeTab) {
        state.activeTab = activeTab;
    },

    [SET_SCROLL_TAB](state, scrollTab) {
        state.scrollTab = scrollTab;
    },

    [SET_APP_VERSION](state, version) {
        state.appVersion = version;
    },

    [SET_COLLAPSE_STATE](state, collapse) {
        state.collapseState = collapse;
    }
};
  
//Actions - Called by component/service-helper
const actions = {

    async loginUserAction({ commit }, loginResult) {
        commit(LOGIN_USER, loginResult);
    },

    async logoutUserAction({ commit }) {
        commit(LOGOUT_USER);
    },

    async setTaxInfoAction({ commit }, taxInfoArgs) {
        commit(SET_TAX_INFO, taxInfoArgs);
    },

    async addTaxInfoAction({ commit }, taxInfoArgs) {
        commit(ADD_TAX_INFO, taxInfoArgs);
    },

    async updateTaxCorrectionsAction({ commit }, taxCorrectionArgs) {
        commit(UPDATE_TAX_CORRECTIONS, taxCorrectionArgs);
    },

    async clearTaxInfoAction({ commit }) {
        commit(CLEAR_TAX_INFO);
    },

    async toggleCountAction({ commit }) {
        commit(TOGGLE_COUNT);
    },

    async setActiveTabAction({ commit }, activeTab) {
        commit(SET_ACTIVE_TAB, activeTab);
    },

    async setScrollTabAction({ commit }, scrollTab) {
        commit(SET_SCROLL_TAB, scrollTab);
    },

    async setAppVersionAction({ commit }, version) {
        commit(SET_APP_VERSION, version);
    },

    async setCollapseStateAction({ commit }, collapse) {
        commit(SET_COLLAPSE_STATE, collapse);
    },

    async setRefreshTokenAction({ commit }, response) {
        commit(REFRESH_TOKEN, response);
    }
};

//Getters - Convenience functions to access state
const getters = {

    isLoggedIn(state) { return state.currentUser !== null; },

    loggedInUser(state, getters) {
        if (getters.isLoggedIn)
            return state.currentUser.username;
        else
            return null;
    },

    getUserRoles(state, getters) {
        if (getters.isLoggedIn)
            return state.currentUser.roles;
        else
            return [];
    },

    token(state, getters) {
        if (getters.isLoggedIn)
            return state.currentUser.token;
        else
            return '';
    },

    currentUserData(state, getters) {
        if (getters.isLoggedIn)
            return state.allUsersData.filter(x => x.user === state.currentUser.username)[0];
        else
            return null;
    },

    hasRequiredRole(state, getters) {
        return role => {
            //Takes in a parameter called role and determines if user has access
            return getters.getUserRoles.indexOf(role) > -1 || getters.getUserRoles.indexOf('Administrator') > -1;
        }
    },

    isAdministrator(state, getters) {
        return role => {
            return getters.getUserRoles.indexOf('Administrator') > -1;
        }
    },

    toggleCount(state) {
        return state.toggleCount;
    },

    currentTab(state) {
        return state.activeTab;
    },

    scrollTab(state) {
        return state.scrollTab;
    },

    currentAppVersion(state) {
        return state.appVersion;
    },

    currentCollapseState(state) {
        return state.collapseState;
    }

};
  
//Export the store using secure-ls
export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state,
    mutations,
    actions,
    getters,
    plugins: [
        createPersistedState({
            key: storageKey,
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ]
});
  