import Vue from 'vue';
import Router from 'vue-router';
import PageNotFound from '@/views/page-not-found';
import store from './store';
import jwt_decode from 'jwt-decode';
import { APP_DIRECTORY } from './shared/config';

Vue.use(Router);

const parseProps = r => ({ id: parseInt(r.params.id) });

const router = new Router({
    mode: 'history',
    base: APP_DIRECTORY,
    routes: [
        {
            path: '/',
            redirect: '/home',
        },
        {
            path: '/home',
            name: 'home',
            props: true,
            component: () =>
                import(/* webpackChunkName: "home.cts" */ './views/home.vue'),
        },
        {
            path: '/user-management',
            name: 'usermanagement',
            component: () =>
                import(/* webpackChunkName: "admin.cts" */ './views/user-management.vue'),
        },
        {
            path: '/coming-soon',
            name: 'comingsoon',
            component: () =>
                import(/* webpackChunkName: "home.cts" */ './views/coming-soon.vue'),
        },
        {
            path: '/login',
            name: 'login',
            props: true,
            component: () =>
                import(/* webpackChunkName: "user.cts" */ './views/login.vue'),
        },
        {
            path: '/new-account',
            name: 'newaccount',
            component: () =>
                import(/* webpackChunkName: "user.cts" */ './views/new-account.vue'),
        },
        {
            path: '/reset-password',
            name: 'resetpassword',
            component: () =>
                import(/* webpackChunkName: "user.cts" */ './views/reset-password.vue'),
        },
        {
            path: '/change-password',
            name: 'changepassword',
            props: true,
            component: () =>
                import(/* webpackChunkName: "user.cts" */ './views/change-password.vue'),
        },
        {
            path: '/activate',
            name: 'activate',
            component: () =>
                import(/* webpackChunkName: "user.cts" */ './views/activate.vue'),
        },
        {
            path: '/unlock',
            name: 'unlock',
            component: () =>
                import(/* webpackChunkName: "user.cts" */ './views/unlock.vue'),
        },
        {
            path: '/my-taxes',
            name: 'mytaxes',
            props: true,
            component: () =>
                import(/* webpackChunkName: "main.cts" */ './views/my-taxes.vue'),
        },
        {
            path: '*',
            component: PageNotFound
        }
    ]
});

/* Protect Routes */
router.beforeEach((to, from, next) => {

    /* Validate the token hasn't expired if the user is logged in */
    if (store.getters.isLoggedIn) {
        try {
            const decoded = jwt_decode(store.getters.token);
            if (decoded.exp - (Date.now() / 1000) < 0) {
                store.dispatch('logoutUserAction');
                setTimeout(() => {
                    router.push({ name: 'login', params: { SessionExpired: true } }).catch(err => { });
                }, 700);
                return;
            }
        }
        catch (error) {
            store.dispatch('logoutUserAction');
            router.push({ name: 'login' }).catch(err => { });
            return;
        }
    }

    /* These page requires no authentication */
    if (to.fullPath === '/home'
        || to.fullPath.includes('/coming-soon')
        || to.fullPath.includes('/new-account')
        || to.fullPath.includes('/activate')
        || to.fullPath.includes('/unlock')
        || to.fullPath.includes('/reset-password')
        || to.fullPath.includes('/change-password')) {
        next(); 
        return;
    }

    /* Ignore login page if user is already authenticated */
    if (to.fullPath === '/login') {
        if (store.getters.isLoggedIn) {
            //Redirect to home page if user is already logged in
            next('/home');
        }
        else {
            //Continue to login page
            next();
        }
    }

    /* Protect routes if the user isn't logged in */
    if (to.fullPath !== '/login') {
        if (!store.getters.isLoggedIn) {
            //Redirect to login page, user not logged in
            next('/login');
        }
        else {
            //Continue to specific page
            next();
        }
    }
});

export default router;